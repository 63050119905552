import EventEmitter from './EventEmitter'

export default class Sizes extends EventEmitter {
    /**
     * Constructor
     */

    constructor() {
        super()

        // Viewport
        this.viewport = {}
        this.$sizeViewport = document.createElement('div')
        this.$sizeViewport.style.width = '100vw'
        this.$sizeViewport.style.height = '100vh'
        this.$sizeViewport.style.position = 'absolute'
        this.$sizeViewport.style.top = 0
        this.$sizeViewport.style.left = 0
        this.$sizeViewport.style.pointerEvents = 'none'

        // Resizing
        this.resize = this.resize.bind(this)
        window.addEventListener('resize', this.resize)

        this.resize()
    }

    /**
     * Resizing
     */

    resize() {
        document.body.appendChild(this.$sizeViewport)
        this.viewport.width = this.$sizeViewport.offsetWidth
        this.viewport.height = this.$sizeViewport.offsetHeight
        document.body.removeChild(this.$sizeViewport)

        this.width = window.innerWidth
        this.height = window.innerHeight

        this.setUserType()

        this.trigger('resize')
    }

    setUserType() {
        if (this.width <= 450 && this.width / this.height < 9 / 16) {
            this.userType = 'mobile'
        } else if (this.width / this.height < 1) {
            this.userType = 'mobile'
        } else {
            this.userType = 'desktop'
        }
    }

}